require('alpinejs');

const bootstrap = require('bootstrap');
import { createPopper } from '@popperjs/core';

const popcorn = document.querySelector('#book-div');
const tooltip = document.querySelector('#tooltip');

const popperInstance = createPopper(popcorn, tooltip, {
  placement: 'top',
});

const popcorn2 = document.querySelector('#brushes');
const tooltip2 = document.querySelector('#tooltip-brush');

const popperInstance2 = createPopper(popcorn2, tooltip2, {
  placement: 'top',
});
const popcorn3 = document.querySelector('#pot');
const tooltip3 = document.querySelector('#tooltip-pot');

const popperInstance3 = createPopper(popcorn3, tooltip3, {
  placement: 'top',
});

const popcorn4 = document.querySelector('#artwork');
const tooltip4 = document.querySelector('#tooltip-art');
const popperInstance4 = createPopper(popcorn4, tooltip4, {
  placement: 'top',
});

const popcorn5 = document.querySelector('#canvas');
const tooltip5 = document.querySelector('#tooltip-canvas');
const popperInstance5 = createPopper(popcorn5, tooltip5, {
  placement: 'top',
});


function show() {
  tooltip.setAttribute('data-show', '');

  // We need to tell Popper to update the tooltip position
  // after we show the tooltip, otherwise it will be incorrect
  popperInstance.update();
}
function show2() {
  tooltip2.setAttribute('data-show', '');

  // We need to tell Popper to update the tooltip position
  // after we show the tooltip, otherwise it will be incorrect
  popperInstance2.update();
}
function show3() {
  tooltip3.setAttribute('data-show', '');

  // We need to tell Popper to update the tooltip position
  // after we show the tooltip, otherwise it will be incorrect
  popperInstance3.update();
}
function show4() {
  tooltip4.setAttribute('data-show', '');

  // We need to tell Popper to update the tooltip position
  // after we show the tooltip, otherwise it will be incorrect
  popperInstance4.update();
}
function show5() {
  tooltip5.setAttribute('data-show', '');

  // We need to tell Popper to update the tooltip position
  // after we show the tooltip, otherwise it will be incorrect
  popperInstance5.update();
}

console.log(popcorn);

function hide() {
  tooltip.removeAttribute('data-show');
}
function hide2() {
  tooltip2.removeAttribute('data-show');
}
function hide3() {
  tooltip3.removeAttribute('data-show');
}
function hide4() {
  tooltip4.removeAttribute('data-show');
}
function hide5() {
  tooltip5.removeAttribute('data-show');
}

const showEvents = ['mouseenter', 'focus'];
const hideEvents = ['mouseleave', 'blur'];

showEvents.forEach((event) => {
  popcorn.addEventListener(event, show);
  popcorn2.addEventListener(event, show2);
  popcorn3.addEventListener(event, show3);
  popcorn4.addEventListener(event, show4);
  popcorn5.addEventListener(event, show5);
});

hideEvents.forEach((event) => {
  popcorn.addEventListener(event, hide);
  popcorn2.addEventListener(event, hide2);
  popcorn3.addEventListener(event, hide3);
  popcorn4.addEventListener(event, hide4);
  popcorn5.addEventListener(event, hide5);
});


